<template>
  <div class="outBox">
    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.outBox {
  width: 100%;
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>